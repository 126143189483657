import type { FC } from 'react';
import type { Theme } from '@material-ui/core';
import type { SxProps } from '@material-ui/system';
import logo from '../res/img/banner_sponsor_last.png';
import {Box} from "@material-ui/core";

interface LogoProps {
  sx?: SxProps<Theme>;
}
const LogoWhite: FC<LogoProps> = (props) => (
    <Box
        component="img"
        src={logo}
        sx={{
            objectFit: "fill",
            width: "100%",
            height: 64,
        }}
    />
);

export default LogoWhite;
