import type {FC} from 'react';
import React, {useEffect} from 'react';
import {Link as RouterLink, useLocation, useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import type {Theme} from '@material-ui/core';
import {Box, Button, Divider, Drawer, ListItem} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Logo from '../Logo';
import Scrollbar from '../Scrollbar';
import i18n from "i18next";
import NavSection from "../NavSection";
import HomeIcon from '@material-ui/icons/Home';
import VideoCameraBack from '@material-ui/icons/VideoCameraBack';
import Info from '@material-ui/icons/Info';
import AirIcon from '@material-ui/icons/Air';
import Radar from '@material-ui/icons/Radar';
import {StackedLineChart} from "@material-ui/icons";
import i18next from "i18next";
import {ChartPeriodType} from "../../model/types/chartPeriodType";
import LogoTecnici from '../LogoTecnici';
interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
  onDisclaimerOpened?: () => void;
}

const sections_user = [
  {
    title: "",
    items: [
        {
            title: i18n.t("home"),
            path: '/',
            popup: false,
            icon: <HomeIcon fontSize="small" />
        },
        {
            title: i18n.t("grafici"),
            path: '/charts',
            popup: false,
            icon: <StackedLineChart fontSize="small" />
        },
        {
            title: i18n.t("vento_dinamico"),
            path: '/windDynamic',
            popup: false,
            icon: <AirIcon fontSize="small" />
        },
        {
            title: i18n.t("webcam"),
            path: '/webcams',
            popup: false,
            icon: <VideoCameraBack fontSize="small" />
        },
    ]
  }
];
const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose,onDisclaimerOpened, openMobile } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const navigate = useNavigate()
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

    let paddingLeft = 10;

  // @ts-ignore
    const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex'
            },
            justifyContent: 'center',
            p: 2
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40
              }}
            />
          </RouterLink>
        </Box>

        <Divider />
        <Box sx={{ p: 2 }}>
            {  sections_user.map((section) =>  (
                <NavSection
                    key={section.title}
                    pathname={location.pathname}
                    sx={{
                        '& + &': {
                            mt: 3
                        }
                    }}
                    {...section}
                />
            ))}
            <Button
                id={`disclaimer`}
                variant="text"
                sx={{
                    color: 'text.secondary',
                    fontWeight: 'fontWeightMedium',
                    justifyContent: 'flex-start',
                    textAlign: 'left',
                    pl: `${paddingLeft}px`,
                    pr: '8px',
                    mt: -1,
                    py: '12px',
                    textTransform: 'none',
                    width: '100%',
                }}
                disableElevation
                onClick={() => {
                    onDisclaimerOpened()
                }}>
                <Info></Info>
                <Box sx={{ ml: 0.8 }} />
                {i18n.t("disclaimer")}
            </Button>

        </Box>
          <LogoTecnici />

      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
